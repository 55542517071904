import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Station from './Station';
import Segment from './Segment';
import Interconnections, {
    InterconnectionsContainer,
} from './Interconnections';
import TimelineToolbar from '../TimelineToolBar';
import {
    addTime,
    timeDiff,
    ISO_8601_FORMAT,
    formatDate,
    timeDuration,
    getUtcOffsetText,
} from '../../util/date';
import { TranslateContext } from '../System/Translations';
import TimezoneContext from '../System/TimezoneHelper/context';

import './Timeline.scss';
import { HADProvider } from '../HadForm';
import PaxRightsPopup from './PaxRightsPopup';

const OPEN_STATION_URL = '/rides/{rideUuid}/stations/{stationUuid}/open';
const CLOSE_STATION_URL = '/rides/{rideUuid}/stations/{stationUuid}/close';
const CANCEL_STATION_URL = '/rides/{rideUuid}/stations/{stationUuid}/cancel';

/**
 * generate proper Interconnections entries to pass to component
 * @func getICEntries
 * @param {array} ic interconnection rides
 * @param {string} compareTime time ISO_8601_FORMAT to detect connection fail
 * @returns {array} valid entries fro Interconnections component
 */
function getICEntries(ic, compareTime, translate) {
    const ARR_DEP_FORMAT = 'HH:mm, DD MMM YYYY';

    return ic.map((entry) => {
        const controlTime = entry.arrival_time || entry.departure_time;
        const estimatedTime = entry.delay
            ? controlTime
            : addTime(
                  entry.delay,
                  'seconds',
                  ISO_8601_FORMAT,
                  controlTime,
                  true
              );
        const timeToConnection = entry.arrival_time
            ? timeDiff(compareTime, estimatedTime, 'minutes')
            : timeDiff(estimatedTime, compareTime, 'minutes');

        let delayStatus = '';
        switch (true) {
            case timeToConnection <= 10:
                delayStatus = 'danger';
                break;
            case timeToConnection > 10 && entry.delay:
                delayStatus = 'warning';
                break;
            default:
                delayStatus = 'success';
        }

        const transferTime = timeDuration(timeToConnection, 'minutes', '*h:mm');
        const arrival = formatDate.parseZone(
            entry.arrival_time,
            ARR_DEP_FORMAT
        );
        const departure = formatDate.parseZone(
            entry.departure_time,
            ARR_DEP_FORMAT
        );
        const arrivalUTC = getUtcOffsetText(entry.arrival_time);
        const departureUTC = getUtcOffsetText(entry.departure_time);

        const arrOrDepTime = entry.arrival_time
            ? `${translate(
                  'rebooking-filters.arrival'
              )}: ${arrival}, ${arrivalUTC}\n\r`
            : `${translate(
                  'rebooking-filters.departure'
              )}: ${departure}, ${departureUTC}\n\r`;
        const hintText = `${arrOrDepTime}${translate(
            'reservations.table.transfer'
        )}: ${transferTime}min`;

        return {
            id: entry.ride_id,
            code: entry.ride_uid,
            color: `${entry.line_color_primary}`,
            count: Number(entry.ic_pax_count),
            delayStatus,
            hintText,
        };
    });
}
export default function Timeline(props) {
    const {
        timeline,
        rideUuid,
        rideIsDelayProcessEnabled,
        rideStatus,
        updateTimeline,
        fetchTimeline,
        withBoosterBuses,
        pax2busDistributionEnabled,
        removeDelaysToTheRest,
        removeDelay,
        setStopDelay,
        extendDelay,
        applyStopDelay,
        hadSavePending,
        bigDelayNotify,
        sendNotify,
        closeBigDelayNotify,
        getPrevStopDelay,
        notify,
        rideType,
        sendNotifyProgress,
    } = props;
    let allSegments = [],
        allStopsIds = [];
    timeline.forEach((cur) => {
        if (cur.entry_type === 'segment') {
            allSegments.push({
                id: cur.id,
                uuid: cur.uuid,
                seats_locked: cur.seats_occupancy.locked,
                bike_slots_locked: cur.bikes_occupancy.locked,
                wheelchairs_locked: cur.wheelchairs_occupancy.locked,
            });
        }
        if (cur.entry_type === 'stop') {
            allStopsIds.push(cur.id);
        }
    });
    const [allToNextSegments, setAllToNextSegments] = useState(false);
    const [allToNextStopsIds, setAllToNextStopsIds] = useState([]);
    const [allToNextSegmentsIds, setAllToNextSegmentsIds] = useState([]);

    const onSetAllToNextSegments = (value, id) => {
        const timelineIndex = timeline.findIndex((item) => item.id === id);
        const allToNextTimeline = timeline.slice(timelineIndex);

        let segmentsIds = [],
            stopsIds = [];

        allToNextTimeline.reduce((acc, cur) => {
            if (cur.entry_type === 'segment') {
                segmentsIds.push(cur.id);
            }
            if (cur.entry_type === 'stop') {
                stopsIds.push(cur.id);
            }

            return null;
        }, null);

        setAllToNextSegments(value);
        setAllToNextStopsIds(stopsIds);
        setAllToNextSegmentsIds(segmentsIds);
    };

    const translate = useContext(TranslateContext);
    const timezone = useContext(TimezoneContext);
    const { isLocal } = timezone;

    let canManualDelay = null;

    const render = timeline.reduce(
        (acc, item, index, array) => {
            let { timelineSequence, interconnectionsIn, interconnectionsOut } =
                acc;
            if (item.entry_type === 'stop') {
                // fill stations
                const {
                    id,
                    uuid,
                    code,
                    name,
                    boarding_pax: paxIn,
                    leaving_pax: paxOut,
                    status,
                    arrival,
                    departure,
                    arrival_delay: arrivalDelay,
                    departure_delay: departureDelay,
                    ata_data: ataData,
                    eta_data: etaData,
                    time_zone,
                } = item;

                const eta = etaData && etaData.RT ? etaData.RT.eta : null;
                const ata = ataData && ataData.RT ? ataData.RT.ata : null;

                const closeStationUrl = CLOSE_STATION_URL.replace(
                    '{rideUuid}',
                    rideUuid
                ).replace('{stationUuid}', uuid);
                const openStationUrl = OPEN_STATION_URL.replace(
                    '{rideUuid}',
                    rideUuid
                ).replace('{stationUuid}', uuid);
                const cancelStationUrl = CANCEL_STATION_URL.replace(
                    '{rideUuid}',
                    rideUuid
                ).replace('{stationUuid}', uuid);

                const isLastStation = typeof departure === 'undefined';

                let isDisabledHAD = false;

                if (allToNextSegments && allToNextStopsIds.length) {
                    const allExceptCurrent = [...allToNextStopsIds].slice(1);
                    isDisabledHAD = allExceptCurrent.includes(item.id);
                }

                if (index === 0) {
                    const dep = isLocal
                        ? formatDate(departure, 'DD MMM, HH:mm')
                        : formatDate.tz(departure, time_zone, 'DD MMM, HH:mm');
                    const now = formatDate(new Date(), 'DD MMM, HH:mm');
                    const diff = timeDiff(dep, now, 'hours');

                    canManualDelay =
                        diff && typeof diff === 'number' ? diff <= 24 : true;
                    canManualDelay = true;
                }

                timelineSequence.push(
                    <Station
                        id={id}
                        uuid={uuid}
                        code={code}
                        name={name}
                        paxIn={paxIn}
                        paxOut={paxOut}
                        status={status}
                        actions={{
                            close: closeStationUrl,
                            open: openStationUrl,
                            cancel: cancelStationUrl,
                        }}
                        arrival={arrival}
                        departure={departure}
                        canManualDelay={canManualDelay}
                        arrivalDelay={arrivalDelay}
                        departureDelay={departureDelay}
                        eta={eta}
                        ata={ata}
                        rideUuid={rideUuid}
                        key={uuid}
                        nextStop={array[index + 2]}
                        time_zone={time_zone}
                        extendDelay={extendDelay}
                        removeDelaysToTheRest={removeDelaysToTheRest}
                        removeDelay={removeDelay}
                        setStopDelay={setStopDelay(item.id)}
                        applyStopDelay={applyStopDelay}
                        getPrevStopDelay={getPrevStopDelay(item.id)}
                        hadSavePending={hadSavePending}
                        isLast={isLastStation}
                        isDisabledHAD={isDisabledHAD}
                        allToNextSegments={allToNextSegments}
                        allToNextStopsIds={allToNextStopsIds}
                        allToNextSegmentsIds={allToNextSegmentsIds}
                        setAllToNextSegments={onSetAllToNextSegments}
                        rideType={rideType}
                    />
                );

                // fill incoming pax IC
                if (item.ic_pax_in.length > 0) {
                    let entries = getICEntries(
                        item.ic_pax_in.filter((ic) => ic.ic_pax_count > 0),
                        addTime(
                            departureDelay,
                            'seconds',
                            ISO_8601_FORMAT,
                            departure,
                            true
                        ),
                        translate
                    );
                    interconnectionsIn.push(
                        <Interconnections
                            entries={entries}
                            key={`ic-in-${uuid}`}
                            isLast={isLastStation}
                        />
                    );
                }
                // fill out pax IC
                if (item.ic_pax_out.length > 0) {
                    let entries = getICEntries(
                        item.ic_pax_out.filter((ic) => ic.ic_pax_count > 0),
                        addTime(
                            arrivalDelay,
                            'seconds',
                            ISO_8601_FORMAT,
                            arrival,
                            true
                        ),
                        translate
                    );
                    interconnectionsOut.push(
                        <Interconnections
                            entries={entries}
                            key={`ic-out-${uuid}_`}
                            isLast={isLastStation}
                        />
                    );
                }

                /* fullfil gaps for station if no IC items
                 * it's only a visual meter
                 * maybe come up with a better approach
                 */
                if (item.ic_pax_in.length === 0) {
                    interconnectionsIn.push(
                        <Interconnections key={`ic-in-${uuid}`} />
                    );
                }
                if (item.ic_pax_out.length === 0) {
                    interconnectionsOut.push(
                        <Interconnections key={`ic-out-${uuid}`} />
                    );
                }
            }
            // fill segments
            if (item.entry_type === 'segment') {
                const {
                    id,
                    bikes_occupancy: bikesOccupancy,
                    seats_occupancy: seatsOccupancy,
                    wheelchairs_occupancy: wheelchairsOccupancy,
                    bus_is_here: busIsHere,
                } = item;

                // get pax form prev stop
                const { boarding_pax: paxIn, leaving_pax: paxOut } =
                    array[index - 1];
                timelineSequence.push(
                    <Segment
                        rideUuid={rideUuid}
                        segmentId={item.id}
                        busIsHere={busIsHere}
                        seatsOccupancy={seatsOccupancy}
                        bikesOccupancy={bikesOccupancy}
                        wheelchairsOccupancy={wheelchairsOccupancy}
                        rideStatus={rideStatus}
                        paxIn={paxIn}
                        paxOut={paxOut}
                        key={id}
                        allSegments={allSegments}
                        fetchTimeline={fetchTimeline}
                        notify={notify}
                        translate={translate}
                    />
                );
            }
            return {
                timelineSequence,
                interconnectionsIn,
                interconnectionsOut,
            };
        },
        {
            timelineSequence: [],
            interconnectionsIn: [],
            interconnectionsOut: [],
        }
    );

    return (
        <div>
            <TimelineToolbar
                key="timeline"
                rideUuid={rideUuid}
                rideIsDelayProcessEnabled={rideIsDelayProcessEnabled}
                updateTimeline={updateTimeline}
                withBoosterBuses={withBoosterBuses}
                pax2busDistributionEnabled={pax2busDistributionEnabled}
                fetchTimeline={fetchTimeline}
            />
            <div className="timeline timeline--scrollable">
                <HADProvider timeline={timeline}>
                    <InterconnectionsContainer type="in">
                        {render.interconnectionsIn}
                    </InterconnectionsContainer>
                    <div className="timeline-wrapper">
                        {render.timelineSequence}
                    </div>
                    <InterconnectionsContainer type="out">
                        {render.interconnectionsOut}
                    </InterconnectionsContainer>
                </HADProvider>
            </div>
            <PaxRightsPopup
                isActive={bigDelayNotify}
                sendNotify={sendNotify}
                onClose={closeBigDelayNotify}
                inProgress={sendNotifyProgress}
            />
        </div>
    );
}
Timeline.propTypes = {
    timeline: PropTypes.array.isRequired,
    rideUuid: PropTypes.string.isRequired,
    rideStatus: PropTypes.string.isRequired,
    withBoosterBuses: PropTypes.bool.isRequired,
    pax2busDistributionEnabled: PropTypes.bool.isRequired,
};
Timeline.defaultProps = {
    withBoosterBuses: false,
    pax2busDistributionEnabled: false,
    rideStatus: '',
};
